.noResult {
  color: red;
}

p {
  text-align: center;
}

ul {
  margin-left:auto;
  margin-right:auto;
  padding:8px;
}


