*{
  box-sizing:border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('https://cdn.pixabay.com/photo/2016/01/27/15/25/space-1164579__340.png');
  background-repeat:no-repeat;
  background-size:cover;
  background-attachment: fixed;
  background-position: center center;
  color: white;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: 'Poller One', cursive;
  text-align: center;
}

h2 {
  text-align: center;
  font-style: italic;
}

main {
  width: 70%;
  min-height: 500px;
  height: auto;
  margin-top:50px;
  margin-bottom: 50px;
  padding: 10px;
  border: 10px double yellow;
  border-radius:10px;
  display: flex;
  flex-direction: column;
  background-color:black;
}

#root {
  /* height:100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorBoundaryMessage {
  color:red;
}

img {
  width: 100px;
  margin-left:auto;
  margin-right:auto;
}